<template>
  <v-form>
    <v-container fluid  grid-list>
      <v-layout wrap>
        <v-flex xs12 sm12 md12 lg12 xl12>
          <v-text-field
            v-model="product.name"
            :error-messages="nameErrors"
            :counter="100"
            label="Naam"
            required
            @input="$v.product.name.$touch()"
            @blur="$v.product.name.$touch()"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 xl12>
          <v-text-field
            v-model="product.description"
            :counter="200"
            label="Omschrijving"
            @input="$v.product.description.$touch()"
            @blur="$v.product.description.$touch()"
          ></v-text-field>  
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 xl12>
          <v-btn color="primary" @click="submit">opslaan</v-btn>
          <v-btn color="primary" @click="back">annuleren</v-btn>
          <span>{{submitStatus}}</span>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
    import { validationMixin } from 'vuelidate'
  import { required, maxLength } from 'vuelidate/lib/validators'

  export default {
    props: ['product'],
    mixins: [validationMixin],
    validations: {
      product: {
        name: { required, maxLength: maxLength(100) },
        description: { maxLength: maxLength(200) }
      }
    },

    data: () => ({
      submitStatus: null
    }),

    computed: {      
      nameErrors () {
        const errors = []
        if (!this.$v.product.$dirty) return errors
        !this.$v.product.name.maxLength && errors.push('Naam kan maximaal 100 tekens bevatten')
        !this.$v.product.name.required && errors.push('Dit veld is verplicht')
        return errors
      }    
    },
    methods: {
      submit() {
        this.$v.product.$touch()
        if (this.$v.$invalid) {
          this.submitStatus = 'ERROR'
        } else {
          if (this.product.id == undefined) {
          this.submitStatus = 'POSTING'
            this.$http.post('api/product/', this.product)
              .then(request => {
                if (request.status == 201) {
                  this.submitStatus = 'OK'
                  this.$emit('done')
                } else {
                  this.submitStatus = 'ERROR'
                }
              })
              .catch(() => { 
                console.error('post error!') /* eslint-disable-line no-console */
                this.submitStatus = 'ERROR'
              })
          } else {
          this.submitStatus = 'PUTTING'
            this.$http.put('api/product/' + this.product.id, this.product)
              .then(request => {
                if (request.status == 201) {
                  this.submitStatus = 'OK'
                  this.$emit('done')
                } else {
                  this.submitStatus = 'ERROR'
                }
              })
              .catch(() => { 
                console.error('put error!') /* eslint-disable-line no-console */
                this.submitStatus = 'ERROR'
              })
          }
        }
      },
      back () {
        this.$v.$reset()
        this.$emit('reset')
      },
      save() {
      }  
    }
  }
</script>