<template>
  <v-layout row wrap>
    <v-flex xs12 sm12 md12 lg12 xl12 v-if="selectedProduct == null && products.length > 0">
      <v-list>
        <v-list-tile
          v-for="product in products"
          :key="product.id"
          avatar
          @click="selectedProduct = product">
          <v-list-tile-content>
            <v-list-tile-title v-text="product.name"></v-list-tile-title>
          </v-list-tile-content>
          <v-list-tile-avatar>
            <v-icon>extension</v-icon>
          </v-list-tile-avatar>
        </v-list-tile>
      </v-list>        
    </v-flex>
    <v-flex xs12 sm12 md12 lg12 xl12 v-if="selectedProduct == null">
      <v-layout justify-end>
        <v-btn @click="addProduct" color="success" small dark>
          nieuw product
          <v-icon right dark>add</v-icon>
        </v-btn>
      </v-layout>
    </v-flex>
    <productForm 
      v-if="selectedProduct != null" 
      :product="selectedProduct" 
      v-on:done="selectedProduct = null"
      v-on:reset="getProducts">
    </productForm>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import productForm from '../../components/ProductForm.vue'
export default {
  name: 'Product',
  components: { 
    productForm
  },
  data () {
    return {
      selectedProduct: null,
      products: []
    }
  },
  computed: {
    ...mapGetters([
      'oidcUser'
    ])
  },
  methods: {
    getProducts () {
      this.selectedProduct = null
      this.$http.get('api/product/')
        .then(request => this.products = request.data)
        .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
    },
    addProduct () {
      this.products.push({
        name: ''
      })
      this.selectedProduct = this.products[this.products.length-1]
    }
  },
  mounted () {
    this.getProducts()
  }
}
</script>